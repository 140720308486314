import Swiper, { Navigation, Pagination } from 'swiper';

(function () {

	let sections = document.querySelectorAll('.acf-section-imagetext .swiper-wrapper');

	console.log(sections);

	sections.forEach(section => {
		new Swiper(section.querySelector('.swiper'), {
			direction: 'horizontal',
			modules: [ Navigation, Pagination ],
			slidesPerView: 1,
			navigation: section.querySelectorAll('.swiper-slide').length > 1 ? {nextEl: section.querySelector('.swiper-button-next'), prevEl: section.querySelector('.swiper-button-prev')} : false,
			loop: section.querySelectorAll('.swiper-slide').length > 1,
			autoplay: {
				delay: 5000,
			}
		});
	})

})();
